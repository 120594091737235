import {Service, ServiceToC} from '../Axios/Service'
import qs from "qs";
import store from '../../store';
const PROFILE = {
    apply: 'VUE_APP_APPLY_PREFIX'
}

function getType(apiType) {
    return process.env[PROFILE[apiType]]
}

class ApplyApi {
    constructor(ApiType) {
        this.ApiType = ApiType;
    }

    /**
     * 审批通过按钮
     * @param id
     * @returns {*}
     */
    businessOpSaveH5(data) {
        let apiType = getType(this.ApiType);
        return ServiceToC({
            url: `/${apiType}/api/applyBusiness/businessOpSaveH5`,
            method: "post",
            data: qs.stringify(data),
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            },
        });
    }

    /**
     * 审批驳回
     * @param id
     * @returns {*}
     */
    businessOpRejectH5(data) {
        let apiType = getType(this.ApiType);
        return ServiceToC({
            url: `/${apiType}/api/applyBusiness/businessOpRejectH5`,
            method: "post",
            data: qs.stringify(data),
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            },
        });
    }

    //审核信息
    approveInfo(data) {
        let apiType = getType(this.ApiType);
        return ServiceToC({
            url: `/${apiType}/api/applyBusiness/approveInfo`,
            method: "post",
            data: qs.stringify(data),
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            },
        });
    }
    firstSendApproval(bid) {
        let apiType = getType(this.ApiType);
        return ServiceToC({
            url: `/${apiType}/api/applyBusiness/firstSendApproval`,
            method: "post",
            data: qs.stringify({ bid: bid }),
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            },
        });
    }
}
export default ApplyApi
