import {getConfigBaseURL, Service, ServiceToC} from "../Axios/Service";
import qs from "qs";
import store from "../../store";
const PROFILE = {
  pay: "VUE_APP_PAY_PREFIX",
};

function getType(apiType) {
  return process.env[PROFILE[apiType]];
}

class PayApi {
  constructor(ApiType) {
    this.ApiType = ApiType;
  }

  getPlanTotalIncomeHttp(id) {
    let apiType = getType(this.ApiType);
    return ServiceToC({
      url: `/${apiType}/H5Api/collection/getPlanTotalIncome?id=${id}`,
      method: "post",
    });
  }

  getInfo(id) {
    let apiType = getType(this.ApiType);
    return ServiceToC({
      url: `/${apiType}/H5Api/collection/getById`,
      method: "post",
      data: qs.stringify({ id: id }),
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    });
  }

  getH5Pay(outTradeNo,totalFee,openId,remark,payId) {
    console.log(payId,'肯定是看看打开');
    let apiType = getType(this.ApiType);
    return ServiceToC({
      url: `/${apiType}/H5/wechat/pay/webPay?outTradeNo=${outTradeNo}&totalFee=${totalFee}&openId=${openId}&remark=${remark}`,
      method: "get",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "payId":payId,
      },
    });
  }
  saveDetail(data) {
    let apiType = getType(this.ApiType);
    return ServiceToC({
      url: `/${apiType}/platApi/collection/saveDetail`,
      method: "post",
      data,
    });
  }

  // 获取微信支付码
  getWechatPayCode(data) {
    let apiType = getType(this.ApiType);
    return ServiceToC({
      url: `/${apiType}/H5Api/collection/getWechatPayCode`,
      method: "post",
      data: qs.stringify(data),
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    });
  }
  //获取订单详情
  queryAndApproveOrders(data) {
    let apiType = getType(this.ApiType);
    return ServiceToC({
      url: `/${apiType}/api/wallet/queryAndApproveOrders`,
      method: "post",
      data: qs.stringify(data),
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    });
  }
  getById(data, id) {
    let apiType = getType(this.ApiType);
    return ServiceToC({
      url: `/${apiType}/api/wallet/viewAuditResults?orderMainId=${id}`,
      method: "post",
      data,
    });
  }
  reApplyH5(data) {
    let apiType = getType(this.ApiType);
    return ServiceToC({
      url: `/${apiType}/api/reApply/reApplyH5`,
      method: "post",
      data: qs.stringify(data),
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    });
  }
  selectDetail(data) {
    let apiType = getType(this.ApiType);
    return ServiceToC({
      url: `/${apiType}/platApi/collection/selectDetail`,
      method: "post",
      data
    });
  }
  sendPayMessage(data) {
    let apiType = getType(this.ApiType);
    return ServiceToC({
      url: `/${apiType}/platApi/collection/sendPayMessage`,
      method: "post",
      data,
    });
  }
}
export default PayApi;
