var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c("van-nav-bar", {
        attrs: { title: "公司收支审批", "left-text": "返回", "left-arrow": "" },
      }),
      _c(
        "div",
        { staticClass: "main", style: { height: _vm.mainHeight + "px" } },
        [
          _c("div", { staticClass: "mainTop" }, [
            _c("p", { staticClass: "mainTopText1" }, [
              _vm._v("收款方/付款方：" + _vm._s(_vm.datas.cpMent)),
            ]),
          ]),
          _c("div", { staticClass: "mainTop" }, [
            _c(
              "p",
              {
                staticClass: "mainTopText2",
                staticStyle: { "margin-right": "0.2rem" },
              },
              [_vm._v("申请人：" + _vm._s(_vm.datas.createName))]
            ),
            _c(
              "p",
              {
                staticClass: "mainTopText2",
                staticStyle: { "margin-right": "0.1rem" },
              },
              [
                _vm._v(
                  "申请时间：" +
                    _vm._s(_vm._f("dateFormatHms")(_vm.datas.createTime)) +
                    " "
                ),
              ]
            ),
          ]),
          _c(
            "div",
            { staticClass: "card" },
            [
              _c(
                "van-collapse",
                {
                  attrs: { border: false },
                  model: {
                    value: _vm.activeNames,
                    callback: function ($$v) {
                      _vm.activeNames = $$v
                    },
                    expression: "activeNames",
                  },
                },
                _vm._l(_vm.datas.reApplyDetailList, function (item) {
                  return _c(
                    "van-collapse-item",
                    {
                      key: item.id,
                      attrs: {
                        icon: "like-o",
                        title: "费用名称：" + item.payName,
                        name: item.id,
                      },
                    },
                    [
                      _c("div", { staticClass: "goodsBox" }, [
                        _c("p", { staticClass: "goodsBoxText1" }, [
                          _vm._v("办公室：" + _vm._s(item.office)),
                        ]),
                        _c("p", { staticClass: "goodsBoxText1" }, [
                          _vm._v("部门：" + _vm._s(item.dept)),
                        ]),
                        _c("p", { staticClass: "goodsBoxText1" }, [
                          _c("span", { staticClass: "goodsBoxText1" }, [
                            _vm._v("单价："),
                            _c("span", { staticClass: "span2" }, [
                              _vm._v(
                                _vm._s(item.price) + _vm._s(item.currency)
                              ),
                            ]),
                          ]),
                          _c("span", { staticClass: "goodsBoxText1" }, [
                            _vm._v("数量："),
                            _c("span", { staticClass: "span2" }, [
                              _vm._v(_vm._s(item.number)),
                            ]),
                          ]),
                          _c("span", { staticClass: "goodsBoxText1" }, [
                            _vm._v("总价："),
                            _c("span", { staticClass: "span2" }, [
                              _vm._v(
                                _vm._s(
                                  item.price * item.number * item.exchange
                                ) + "¥"
                              ),
                            ]),
                          ]),
                        ]),
                        item.remark
                          ? _c("p", { staticClass: "goodsBoxText1" }, [
                              _vm._v("备注：" + _vm._s(item.remark)),
                            ])
                          : _vm._e(),
                      ]),
                    ]
                  )
                }),
                1
              ),
            ],
            1
          ),
        ]
      ),
      _c("div", { staticClass: "footer" }, [
        _c("p", { staticClass: "footerText1" }, [
          _vm._v("合计："),
          JSON.stringify(_vm.datas) !== "{}"
            ? _c("span", [_vm._v(_vm._s(_vm.totalPrice) + "¥")])
            : _vm._e(),
        ]),
        _vm.datas.status === 1
          ? _c(
              "div",
              { staticClass: "footerBtnBox" },
              [
                _c(
                  "van-button",
                  {
                    staticClass: "footerBtns",
                    staticStyle: { "margin-right": "0.2rem" },
                    attrs: { size: "small", type: "primary" },
                    on: { click: _vm.businessOpSaveH5 },
                  },
                  [_vm._v("审批通过")]
                ),
                _c(
                  "van-button",
                  {
                    staticClass: "footerBtns",
                    attrs: { size: "small", type: "danger" },
                    on: { click: _vm.rejectClick },
                  },
                  [_vm._v("驳回")]
                ),
              ],
              1
            )
          : _vm._e(),
        _vm.datas.status === 2
          ? _c(
              "div",
              { staticClass: "footerBtnBox" },
              [
                _c(
                  "van-button",
                  {
                    staticClass: "footerBtns",
                    attrs: { size: "small", type: "primary" },
                  },
                  [_vm._v("审核已通过")]
                ),
              ],
              1
            )
          : _vm._e(),
        _vm.datas.status === 3
          ? _c(
              "div",
              { staticClass: "footerBtnBox1" },
              [
                _c(
                  "van-button",
                  {
                    staticClass: "footerBtns",
                    attrs: { size: "small", color: "#627177" },
                  },
                  [_vm._v("审核已驳回")]
                ),
                _c("p", [_vm._v("驳回理由：" + _vm._s(_vm.datas.returnMsg))]),
              ],
              1
            )
          : _vm._e(),
      ]),
      _c(
        "van-dialog",
        {
          attrs: { title: "驳回理由", "show-cancel-button": "" },
          on: { confirm: _vm.confirm },
          model: {
            value: _vm.show,
            callback: function ($$v) {
              _vm.show = $$v
            },
            expression: "show",
          },
        },
        [
          _c("van-field", {
            attrs: { placeholder: "请输入驳回理由" },
            model: {
              value: _vm.chunDatas.returnMsg,
              callback: function ($$v) {
                _vm.$set(_vm.chunDatas, "returnMsg", $$v)
              },
              expression: "chunDatas.returnMsg",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }