export const navTabData = [
  {
    name: "住",
    typeName: "hotel",
    title: "酒店资源",
    maintain: "房型维护",
    code: 0,
  },
  {
    name: "会",
    typeName: "site",
    title: "会议资源",
    maintain: "会场维护",
    code: 1,
  },
  {
    name: "景",
    typeName: "spot",
    title: "景点资源",
    maintain: "景点产品维护",
    code: 2,
  },
  {
    name: "车",
    typeName: "traffic",
    title: "用车资源",
    maintain: "用车维护",
    code: 3,
  },
  {
    name: "餐",
    nameEn: "restaurant",
    title: "用餐资源",
    code: 4,
  },
  {
    name: "导",
    typeName: "guide",
    title: "导游资源",
    maintain: "导游维护",
    code: 5,
  },
  {
    name: "签",
    typeName: "visa",
    title: "签证资源",
    maintain: "签证维护",
    code: 6,
  },
  {
    name: "商",
    typeName: "business",
    title: "商务资源",
    maintain: "商务维护",
    code: 7,
  },
  {
    name: "特",
    typeName: "charact",
    title: "特色资源",
    maintain: "特色维护",
    code: 8,
  },
];

export const typeNemeList = {
  hotel: "0",
  site: "1",
  spot: "2",
  traffic: "3",
  restaurant: "4",
  guide: "5",
  visa: "6",
  business: "7",
  charact: "8",
  service: "9",
};
export const typeNemeList1 = {
  0: {
    name: "住",
    typeName: "hotel",
    typeNameUpperCase: "Hotel",
    title: "酒店资源",
    maintain: "房型维护",
    pic: "https://plan-export-icon.oss-cn-beijing.aliyuncs.com/%E5%9B%BD%E5%86%85%E9%85%92%E5%BA%97.png",
    code: 0,
  },
  1: {
    name: "会",
    typeName: "site",
    title: "会议资源",
    maintain: "会场维护",
    pic: "https://plan-export-icon.oss-cn-beijing.aliyuncs.com/%E4%BC%9A%E8%AE%AE%20%283%29.png",
    code: 1,
  },
  2: {
    name: "景",
    typeName: "spot",
    title: "景点资源",
    maintain: "景点产品维护",
    pic: "https://plan-export-icon.oss-cn-beijing.aliyuncs.com/%E7%83%AD%E9%97%A8%E6%99%AF%E7%82%B9.png",
    code: 2,
  },
  3: {
    name: "车",
    typeName: "traffic",
    title: "用车资源",
    maintain: "用车维护",
    pic: "https://plan-export-icon.oss-cn-beijing.aliyuncs.com/%E5%87%BA%E7%A7%9F.png",
    code: 3,
  },
  4: {
    name: "餐",
    nameEn: "restaurant",
    title: "用餐资源",
    pic: "https://plan-export-icon.oss-cn-beijing.aliyuncs.com/%E7%94%A8%E9%A4%90%E5%B0%B1%E9%A4%90.png",
    code: 4,
  },
  5: {
    name: "导",
    typeName: "guide",
    title: "导游资源",
    maintain: "导游维护",
    pic: "https://plan-export-icon.oss-cn-beijing.aliyuncs.com/%E5%AF%BC%E6%B8%B8.png",
    code: 5,
  },
  6: {
    name: "签",
    typeName: "visa",
    title: "签证资源",
    maintain: "签证维护",
    pic: "https://plan-export-icon.oss-cn-beijing.aliyuncs.com/%E7%AD%BE%E8%AF%81.png",
    code: 6,
  },
  7: {
    name: "商",
    typeName: "business",
    title: "商务资源",
    maintain: "商务维护",
    pic: "https://plan-export-icon.oss-cn-beijing.aliyuncs.com/Businessman.png",
    code: 7,
  },
  8: {
    name: "特",
    typeName: "charact",
    title: "特色资源",
    maintain: "特色维护",
    pic: "https://plan-export-icon.oss-cn-beijing.aliyuncs.com/%E7%89%B9%E8%89%B2%E4%BD%93%E9%AA%8C.png",
    code: 8,
  },
  9: {
    name: "服",
    typeName: "service",
    title: "特色资源",
    maintain: "特色维护",
    pic: "",
    code: 9,
  },
};
export const typeNemeList2 = {
  0: {
    name: "住",
    typeName: "hotel",
    typeNameCn: "酒店",
    typeNameUpperCase: "Hotel",
    title: "酒店资源",
    maintain: "房型维护",
    productName: "房型",
    code: 0,
  },
  1: {
    name: "会",
    typeName: "site",
    typeNameCn: "会场",
    typeNameUpperCase: "Site",
    title: "会议资源",
    maintain: "会场维护",
    productName: "会议室",
    code: 1,
  },
  2: {
    name: "景",
    typeName: "spot",
    typeNameCn: "景点",
    typeNameUpperCase: "Spot",
    title: "景点资源",
    maintain: "景点产品维护",
    productName: "景点产品",
    code: 2,
  },
  3: {
    name: "车",
    typeName: "traffic",
    typeNameCn: "用车",
    typeNameUpperCase: "Traffic",
    title: "用车资源",
    maintain: "用车维护",
    productName: "用车产品",
    code: 3,
  },
  4: {
    name: "餐",
    nameEn: "restaurant",
    typeNameCn: "用餐",
    typeNameUpperCase: "Restaurant",
    title: "用餐资源",
    productName: "菜品",
    code: 4,
  },
  5: {
    name: "导",
    typeName: "guide",
    typeNameCn: "导游",
    typeNameUpperCase: "Guide",
    title: "导游资源",
    maintain: "导游维护",
    productName: "人员产品",
    code: 5,
  },
  6: {
    name: "签",
    typeName: "visa",
    typeNameCn: "签证",
    typeNameUpperCase: "Visa",
    title: "签证资源",
    maintain: "签证维护",
    productName: "签证产品",
    code: 6,
  },
  7: {
    name: "商",
    typeName: "business",
    typeNameCn: "商务",
    typeNameUpperCase: "Business",
    title: "商务资源",
    maintain: "商务维护",
    productName: "商务产品",
    code: 7,
  },
  8: {
    name: "特",
    typeName: "charact",
    typeNameCn: "特色",
    typeNameUpperCase: "Charact",
    title: "特色资源",
    maintain: "特色维护",
    productName: "特色产品",
    code: 8,
  },
  9: {
    name: "服",
    typeName: "service",
    typeNameCn: "服务",
    typeNameUpperCase: "Service",
    title: "特色资源",
    maintain: "特色维护",
    productName: "服务产品中文名称",
    code: 9,
  },
    14: {
        name: "其他",
        typeName: "other",
        typeNameCn: '其他',
        typeNameUpperCase: "Other",
        title: "其他",
        maintain: "",
        productName: "其他",
        code: 14,
    },
  15: {
    name: "交",
    typeName: "bigTraffic",
    typeNameCn: "交通",
    typeNameUpperCase: "BigTraffic",
    title: "特色资源",
    maintain: "特色维护",
    code: 15,
  },
};
export const resourceType = [
  {
    name: "全",
    typeName: "all",
    code: null,
  },
  {
    name: "住",
    typeName: "hotel",
    title: "酒店资源",
    maintain: "房型维护",
    code: 0,
  },
  {
    name: "会",
    typeName: "site",
    title: "会议资源",
    maintain: "会场维护",
    code: 1,
  },
  {
    name: "景",
    typeName: "spot",
    title: "景点资源",
    maintain: "景点产品维护",
    code: 2,
  },
  {
    name: "车",
    typeName: "traffic",
    title: "用车资源",
    maintain: "用车维护",
    code: 3,
  },
  {
    name: "餐",
    nameEn: "restaurant",
    title: "用餐资源",
    code: 4,
  },
  {
    name: "导",
    typeName: "guide",
    title: "导游资源",
    maintain: "导游维护",
    code: 5,
  },
  {
    name: "签",
    typeName: "visa",
    title: "签证资源",
    maintain: "签证维护",
    code: 6,
  },
  {
    name: "商",
    typeName: "business",
    title: "商务资源",
    maintain: "商务维护",
    code: 7,
  },
  {
    name: "特",
    typeName: "charact",
    title: "特色资源",
    maintain: "特色维护",
    code: 8,
  },
  {
    name: "服",
    typeName: "service",
    title: "特色资源",
    maintain: "特色维护",
    code: 9,
  },
  {
    name: "服",
    typeName: "service",
    title: "特色资源",
    maintain: "特色维护",
    code: 9,
  },
];
export const daysOption = [
  //天数待选项
  {
    value: "1晚",
    label: "1晚",
  },
  {
    value: "0.5天",
    label: "0.5天",
  },
  {
    value: "1天",
    label: "1天",
  },
  {
    value: "2天 /天",
    label: "2天 /天",
  },
  {
    value: "3-4天 /天",
    label: "3-4天 /天",
  },
  {
    value: "5-7天 /天",
    label: "5-7天 /天",
  },
  {
    value: "7-14天 /天",
    label: "7-14天 /天",
  },
  {
    value: "14天以上",
    label: "14天以上",
  },
];
//复制方法
export function copy(item, that) {
  let oInput = document.createElement("input");
  oInput.value = item;
  document.body.appendChild(oInput);
  oInput.select();
  document.execCommand("Copy");
  that.$message.success("复制成功");
  oInput.remove();
}
//分组
export function getGroup(data, key) {
  let groups = {};
  data.forEach((c) => {
    let value = c[key];
    groups[value] = groups[value] || [];
    groups[value].push(c);
  });
  return groups;
}
export function countryNum(str) {
  const regex = /(\d+)国/;
  const match = str.match(regex);
  if (match) {
    return match[1];
  } else {
    return 0;
  }
}
export function dateFormatHM(time, s) {
  //改变时间格式函数
  var hour = new Date(time).getTimezoneOffset() * 60000;
  var date = new Date(time + 28800000 + hour),
    h = date.getHours(),
    hours = h < 10 ? "0" + h : h,
    m = date.getMinutes(),
    minutes = m < 10 ? "0" + m : m;
  if (!s) {
    s = ":";
  }
  return hours + s + minutes;
}
function convertToHyphenFormat(dateString) {
  const hyphenFormatRegex = /^\d{4}-\d{2}-\d{2}$/;
  if (!hyphenFormatRegex.test(dateString)) {
    return dateString.replace(/\./g, "-");
  }
  return dateString;
}
export function days(satrDate, endDate, type) {
  let star = new Date(convertToHyphenFormat(satrDate));
  let end = new Date(convertToHyphenFormat(endDate));
  let timeDiff = Math.abs(end.getTime() - star.getTime());
  let stayDays;
  stayDays = timeDiff / (1000 * 3600 * 24);
  return stayDays;
}
export function getUrlParams(key) {
  let reg = new RegExp("(^|&)" + key + "=([^&]*)(&|$)");
  let r = window.location.search.substr(1).match(reg);
  if (r != null) return unescape(r[2]);
  return null;
}
//日期加1
export function addOneDay(dateStr) {
  // 将日期字符串转换为标准日期格式
  const formattedDate = dateStr.replace(/\./g, "-");

  // 加一天
  const currentDate = new Date(formattedDate);
  currentDate.setDate(currentDate.getDate() + 1);

  // 格式化新日期
  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, "0");
  const day = String(currentDate.getDate()).padStart(2, "0");

  const newFormattedDate = `${year}-${month}-${day}`;

  return newFormattedDate;
}

export function numberToChinese(number) {
  const fraction = ['角', '分'];
  const digit = [
      '零', '壹', '贰', '叁', '肆',
      '伍', '陆', '柒', '捌', '玖'
  ];
  const unit = [
      ['元', '万', '亿'],
      ['', '拾', '佰', '仟']
  ];
  let head = number < 0 ? '欠' : '';
  number = Math.abs(number);
  let s = '';
  for (let i = 0; i < fraction.length; i++) {
      s += (digit[Math.floor(number * 10 * Math.pow(10, i)) % 10] + fraction[i]).replace(/零./, '');
  }
  s = s || '整';
  number = Math.floor(number);
  for (let i = 0; i < unit[0].length && number > 0; i++) {
      let p = '';
      for (let j = 0; j < unit[1].length && number > 0; j++) {
          p = digit[number % 10] + unit[1][j] + p;
          number = Math.floor(number / 10);
      }
      s = p.replace(/(零.)*零$/, '')
          .replace(/^$/, '零')
          + unit[0][i] + s;
  }
  return head + s.replace(/(零.)*零元/, '元')
      .replace(/(零.)+/g, '零')
      .replace(/^整$/, '零元整');
}

// export function numberToChinese(number, currency) {
//   // 定义货币单位
//   const currencyUnits = {
//     '¥': ["元", "角", "分"],
//     USD: ["美元", "美分"],
//     "€": ["欧元", "分"],
//   };

//   // 如果未指定货币，则默认为人民币（CNY）
//   currency = currency || "CNY";

//   // 获取货币单位
//   const units = currencyUnits[currency];
//   console.log(units,'看看坎坎坷坷');
//   if (!units) {
//     return "不支持的货币类型";
//   }

//   const fraction = units.slice(1);
//   const digit = ["零", "壹", "贰", "叁", "肆", "伍", "陆", "柒", "捌", "玖"];

//   const unit = [
//     ["元", "万", "亿"],
//     ["", "拾", "佰", "仟"],
//   ];

//   let head = number < 0 ? "欠" : "";
//   number = Math.abs(number);
//   let s = "";

//   for (let i = 0; i < fraction.length; i++) {
//     s += (
//       digit[Math.floor(number * 10 * Math.pow(10, i)) % 10] + fraction[i]
//     ).replace(/零./, "");
//   }
//   s = s || "整";
//   number = Math.floor(number);

//   for (let i = 0; i < unit[0].length && number > 0; i++) {
//     let p = "";
//     for (let j = 0; j < unit[1].length && number > 0; j++) {
//       p = digit[number % 10] + unit[1][j] + p;
//       number = Math.floor(number / 10);
//     }
//     s = p.replace(/(零.)*零$/, "").replace(/^$/, "零") + unit[0][i] + s;
//   }
//   return (
//     head +
//     s
//       .replace(/(零.)*零元/, "元")
//       .replace(/(零.)+/g, "零")
//       .replace(/^整$/, "零" + units[0] + "整")
//   );
// }
