<!-- 公司收支审批 -->
<template>
    <div class='container'>
        <van-nav-bar title="公司收支审批" left-text="返回" left-arrow> </van-nav-bar>
        <div class="main" :style="{ height: mainHeight + 'px' }">
            <div class="mainTop">
                <p class="mainTopText1">收款方/付款方：{{ datas.cpMent }}</p>
            </div>
            <div class="mainTop">
                <p class="mainTopText2" style="margin-right: 0.2rem;">申请人：{{ datas.createName }}</p>
                <p class="mainTopText2" style="margin-right: 0.1rem;">申请时间：{{ datas.createTime | dateFormatHms }} </p>
            </div>
            <div class="card">
                <van-collapse :border="false" v-model="activeNames">
                    <van-collapse-item v-for="(item) in datas.reApplyDetailList" :key="item.id" icon="like-o"
                        :title="'费用名称：' + item.payName" :name="item.id">
                        <div class="goodsBox">
                            <p class="goodsBoxText1">办公室：{{ item.office }}</p>
                            <p class="goodsBoxText1">部门：{{ item.dept }}</p>
                            <p class="goodsBoxText1">
                                <span class="goodsBoxText1">单价：<span class="span2">{{ item.price }}{{ item.currency
                                }}</span></span>
                                <span class="goodsBoxText1">数量：<span class="span2">{{ item.number }}</span></span>
                                <span class="goodsBoxText1">总价：<span class="span2">{{
                                    item.price * item.number * item.exchange }}¥</span></span>
                            </p>
                            <p v-if="item.remark" class="goodsBoxText1">备注：{{ item.remark }}</p>
                        </div>
                    </van-collapse-item>
                </van-collapse>
            </div>
        </div>
        <div class="footer">
            <p class="footerText1">合计：<span v-if="JSON.stringify(datas) !== '{}'">{{ totalPrice }}¥</span></p>
            <div class="footerBtnBox" v-if="datas.status === 1">
                <van-button class="footerBtns" @click="businessOpSaveH5" style="margin-right: 0.2rem;" size="small"
                    type="primary">审批通过</van-button>
                <van-button @click="rejectClick" class="footerBtns" size="small" type="danger">驳回</van-button>
            </div>
            <div v-if="datas.status === 2" class="footerBtnBox">
                <van-button class="footerBtns" size="small" type="primary">审核已通过</van-button>
            </div>
            <div v-if="datas.status === 3" class="footerBtnBox1">
                <van-button class="footerBtns" size="small" color="#627177">审核已驳回</van-button>
                <p>驳回理由：{{ datas.returnMsg }}</p>
            </div>
        </div>
        <van-dialog @confirm="confirm" v-model="show" title="驳回理由" show-cancel-button>
            <van-field v-model="chunDatas.returnMsg" placeholder="请输入驳回理由" />
        </van-dialog>
    </div>
</template>

<script>
import PayApi from "@/components/Api/pay";
import ApplyApi from '@/components/Api/apply'
import { Toast, Dialog } from 'vant';
import { getUrlParams } from '@/utils/unit'
export default {
    components: {},
    data() {
        return {
            activeNames: [],
            mainHeight: 0,
            datas: {},
            show: false,
            chunDatas: {
                returnMsg: ''
            }
        }
    },
    computed: {
        totalPrice() {
            let price = 0
            this.datas.reApplyDetailList.forEach(item => {
                price += item.price * item.exchange * item.number
            })
            return price
        }
    },
    watch: {},
    methods: {
        getDatas() {
            Toast.loading({
                message: '加载中...',
                forbidClick: true,
            });
            let obj = {
                id: this.chunDatas.reId,
            }
            new PayApi("pay").reApplyH5(obj).then(res => {
                console.log(res, '看看返回的数据');
                if (res.status == 200) {
                    this.datas = res.data
                    this.activeNames = [...this.activeNames, ...this.datas.reApplyDetailList.map(n => n.id)]
                    setTimeout(() => {
                        const footerHeight = this.$el.querySelector('.footer').clientHeight;
                        this.mainHeight = window.innerHeight - footerHeight - 46;
                    })
                    Toast.clear();
                } else {
                    Toast(res.statusText);
                }
            })
        },
        //审批通过
        businessOpSaveH5() {
            Dialog.confirm({
                title: '审批',
                message: '是否通过审批？',
            }).then(() => {
                Toast.loading({
                    message: '审批中...',
                    forbidClick: true,
                });
                new ApplyApi("apply").businessOpSaveH5(this.chunDatas).then(res => {
                    Toast.loading({
                        message: '审批中...',
                        forbidClick: true,
                    });
                    if (res.status === 200) {
                        Toast("审批成功...");
                        this.getDatas()
                    } else {
                        Toast(res.statusText);
                    }
                })
            })
        },
        //驳回
        rejectClick() {
            this.show = true
        },
        //确认
        confirm() {
            new ApplyApi("apply").businessOpRejectH5(this.chunDatas).then(res => {
                Toast.loading({
                    message: '驳回中...',
                    forbidClick: true,
                });
                if (res.status === 200) {
                    if (res.status == 200) {
                        Toast("驳回成功...");
                        this.getDatas()
                    } else {
                        Toast(res.statusText);
                    }
                }
            })
        },
    },
    created() {

    },
    mounted() {
        this.chunDatas = {
            reId: getUrlParams('reId'),
            configId: getUrlParams('configId'),
            userId: getUrlParams('userId'),
            step: getUrlParams('step'),
            approvalBusinessId: getUrlParams('approvalBusinessId'),
        }
        this.getDatas()
    },
}
</script>
<style lang='scss' scoped>
.container {
    .main {
        padding: 0.2rem;
        overflow-y: scroll;

        .mainTop {
            margin-bottom: 0.1rem;
            display: flex;
            align-items: center;

            .mainTopText1 {
                font-size: 0.37rem;
                margin-right: 0.1rem;
                font-weight: bold;


            }

            .mainTopText2 {
                font-family: 思源宋体 Medium;
                font-size: 0.2rem;
                color: #838383;
            }
        }

        .orderMainIdNumber {
            font-family: 思源宋体 Medium;
            font-size: 0.2rem;
            color: #838383;
            margin-bottom: 0.1rem;
        }

        .card {
            box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
            padding: 0.2rem;
            border-radius: 0.1rem;
            margin-bottom: 0.3rem;

            .goodsBox {
                .goodsBoxText1 {
                    color: #333333;
                    font-size: 0.27rem;
                    font-family: SourceHanSansSC-bold;
                    font-weight: bold;

                    span {
                        font-size: 0.25rem;
                    }

                    .span2 {
                        color: red;
                        font-size: 0.27rem;
                        margin-right: 0.1rem;
                    }
                }


            }
        }
    }

    .footer {
        padding: 0.35rem;
        width: 100%;

        .footerText1 {
            text-align: right;
            color: #333333;
            font-size: 0.32rem;
            font-family: SourceHanSansSC-bold;
            font-weight: bold;
            margin-bottom: 0.3rem;

            span {
                color: red;
            }
        }

        .footerBtnBox {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .footerBtns {
                width: 100%;
            }
        }

        .footerBtnBox1 {
            display: flex;
            align-items: center;
            flex-direction: column;

            .footerBtns {
                margin-bottom: 0.1rem;
                width: 100%;
            }
        }
    }
}
</style>